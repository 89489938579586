.contact_area_wrapper{
    h2{
        font-size:1.2rem !important;
      
        line-height: 0px;
    }
}
.contact_form_wrapper{
    margin:5% 0;
  
    h3{
        font-size:1rem;
        line-height: 0px;
    }

    .form-element{
        margin-bottom:10px;
    }

    input{
        border:#ccc 1px solid;
        width:100%;
        padding:10px;
    }

    textarea{
        width:100%; min-height:150px;
        padding:10px;
    }

    .form-element-label{
        display:none;
    }

    .form-has-error{
        border:#ff0000 1px solid;
    }

}