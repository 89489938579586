.main-area{
    padding:7% 0;
}
// .contact-us{
//     .main-area{
//         padding:7% 0 0 0;
//     } 
// }

.innerPageBody{
    background: #f7f7d4;

    .main-area{
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ceceb1+1,f7f7d4+8 */
background: rgb(206,206,177); /* Old browsers */
background: -moz-linear-gradient(top, rgba(206,206,177,1) 1%, rgba(247,247,212,1) 8%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(206,206,177,1) 1%,rgba(247,247,212,1) 8%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(206,206,177,1) 1%,rgba(247,247,212,1) 8%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ceceb1', endColorstr='#f7f7d4',GradientType=0 ); /* IE6-9 */
    }
}

