$c-white: #fff;
$c-black: #242323;

$c-smoke: #f1f1f1;
$c-gray: #4f4d4c;
$c-light-gray: #70798b;

$c-blue: #3189c2;
$c-charcoal: #222;
$c-orange: #f16522;

$base-font-stack: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


// bootstrap
$headings-margin-bottom: 1rem;
