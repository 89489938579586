#faqAccordion{
    .card-header{
        background: #b234a1;
        padding: 0.25rem 0rem;
    }
    .btn-heading{
       font-size: 1rem;
       text-align: left;
       outline: none;
       color:#fff;
      
    }
    .btn{
        margin-left:0px;
        padding-left:15px;
        text-transform:none;
    }
 
    .btn.focus, .btn:focus {
        outline: 0;
        box-shadow:none;
    }
    .btn::before{
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: '\f068';
        padding-right: 15px;
    }

    .collapsed::before{
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: '\f067';
        padding-right: 15px;
    }
    
    .card{
        margin-bottom:5px;
    }
}