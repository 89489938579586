.footer{
    background:#363e3f;
    .footer_wapper{
        padding:5% 0; 
    }
    .container{
        color:#fff; 
        text-align: center; 
        //padding:5% 0;

        ul{ list-style-type: none;   margin:0; padding:0; }
        
        .copyright_area{ text-align: center;  }

        .footer_main_menu_area{
            margin-top:20px;
            .nav {
                display: block;
            }
            ul.menu{
                li:first-child{
                    font-weight: bold; 
                    font-size:1.1rem;
                }
                li a {
                    text-decoration:none;
                    color:#fff; 
                    width:100%;
                }
            }
        }

        .connect_with_us{
            text-align: center; margin-top:20px;
            .fab{
                margin-top:5px;
                font-size: 35px;
                color: #fff;
                padding: 10px 10px 2px 20px;
                border-radius: 3px;
                background: #3c5a99;
            }
        }
    }

}