.btn{
    display: inline-block;
    padding: 0.8rem 2.5rem;
    transition-duration: 0.15s;
    background:#b234a1;
    color:#fff;
    line-height: 1;
    text-transform: uppercase;
    font-size:0.8rem;

    @include hover {
        text-decoration: none;
        color:#fff;
    }

    [class*=fa] {
        vertical-align: middle;
        font-size: 25px;
        margin-left: 10px;
      }

  }

  .btn-primary{
      
  }