.welcomeText{
    max-width:550px; margin:5% auto;
    text-align: center;
}

.welcomeBoxes{
    .avatar{
        vertical-align: middle;
        width: 90px;
        height:90px;
        border-radius: 50%;
        border:#fff 3px solid;
        margin-bottom:10px;
    }
    .row{
        text-align: center;
        padding:0; margin:0;
      
    }
    .row >div:nth-child(1){
        background: #01bfa5;
    }
    .row >div:nth-child(2){
        background: #fee25b;
        color:#000;
    }
    .row >div:nth-child(3){
        background: #ee534f;
    }
    .row >div{
        padding:50px 10px; color:#fff;
        p{ max-width:350px; margin:auto; }
        .starRating{
            margin-top:40px;
            p{ font-size:12px;}
        }
    }
}