#photoTour{
    max-width: 800px;
    margin: auto;
    .mainImg{
        img{
            max-width:500px;
            position: relative;
            top:-60px;
            margin: auto;
            cursor: pointer;
        }
    }
}

.hideScroll{
    overflow: hidden;
}

.gallery_wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(255, 255, 255, 0.6);
    display: none;
    
    .gallery_overlay{
        .closeBtn{
            position: absolute;
            z-index:99999;
            right:12px;
            top:0;
            color:#000;
            font-size: 1.5rem;
        }
    }
    .nav{
        display: none;
    }
    .nav-bottom{
        .full-screen{
            display:none !important;
            border:#ff0000 1px solid;
        }
    }
}
