@media only screen and (max-width: 768px) {
    .overlayMenu{
        position: fixed;
    }

    .subBanner {
        position: relative;
    }

    .logo_area{
        img{ max-height:50px;}
    }
   
}

@media only screen and (max-width: 500px) {
    .innerPageBody{
        .innerBannerImage{
            img{
                height: 90px;
            }
        }
    }
    .about-jessie-brown{
        .item-page{
            img{ width: 100% !important; }
        }
    }

    #photoTour .mainImg img {
        top:15px;
        width:100%;
    }

    .home{
        .subBanner {
            position: relative;
            height: 320px;
            background-size: cover;
            padding-top: 1px;
            z-index:0 !important;
            .col-md-4 {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 30px;
            }
        }
    }

    #faqAccordion{
        .btn {
            white-space: inherit !important;
            line-height: 1.5;
        }
    }
    

   
}