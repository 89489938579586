.full_header{
    position: relative;
}
.header{
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+1,000000+100&0.65+15,0.46+66,0.24+86,0+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 1%, rgba(0,0,0,0.65) 15%, rgba(0,0,0,0.46) 66%, rgba(0,0,0,0.24) 86%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 1%,rgba(0,0,0,0.65) 15%,rgba(0,0,0,0.46) 66%,rgba(0,0,0,0.24) 86%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0.65) 1%,rgba(0,0,0,0.65) 15%,rgba(0,0,0,0.46) 66%,rgba(0,0,0,0.24) 86%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

width:100%;
position:absolute;
z-index:9; 

 .container-fluid{
    margin-top:20px;
 }

 

}

.logo_area{
    img{ max-height:120px;}
}
.main_menu_area{
    a{
         float:right;
         color:#fff;
         text-decoration:none;
         text-align: center;
         .fas{ font-size:1.6rem; }
         p{ font-size:0.5rem; line-height:5px; }
    }
    
}

.banner{
    position: relative;
    
}
.subBanner{
    position: absolute;
    left: 0;
    bottom: 5%;

    .col-md-4 {
        flex: 0 0 28.33333%;
        max-width: 28.33333%;
    }
    
    //height: 100px;
    width:100%;
    z-index: 99;
    .row{
          margin:15px 0px; height:75px;
        .section{ 
              a{ text-decoration:none;}
              background:#e76bd6; 
              padding:0; margin:0;
              margin-right:5%;
            .row{ padding:0; margin:0;
                .col-4{ position: relative; }
                div{ padding:0px;
                    // border:#ff0000 1px solid;
                    position: relative;
                    img{ width:100%;}
                    h3{ font-size: 12px; text-transform:uppercase; color:#f296e6;}
                    p{ font-size:14px; font-weight:bold; color:#fff; line-height:0px;}
                }
                .overlayDiv{
                    position: relative; left:0; top:12px;
                }
                .overlayDiv2{
                    position: relative; left:0px; top:-9px;
                    img{ width:100%; }
                }
            }
        }
        .col:last-child{ margin-right:0; }
    }

    .hideExtra{ overflow: hidden;}
  
}


.overlayMenu{
    //border:#ff0000 1px solid;
    position: absolute;
    left: 0;
    top: 0;
    z-index:1;
    width:100%;
    height: 100%;
    background-color: rgba(178, 52, 161, 0.9);
    display:none;
    
    .nav {
        display: block;
    }
    ul.menu{ 
        //border:#ff0000 1px solid;
        margin:auto;
        list-style-type: none;   
        text-align: center;
        margin:0; padding:0;  margin-top:10%;
        li{
            color: #fff;
            max-width: 298px;
            margin: auto;
            line-height: 3rem;
            font-weight: 100;
            border-bottom: 1px solid #b938b6;
        }
        li:first-child{
            font-size:1.4rem;
            color:#cc56c9;
            font-weight: 700;
        }
        li a {
            text-decoration:none;
            width:100%;
            color:#fff; 
            font-size: 1.4rem;
            display:block;
            
        }
    }
}

.innerbanner{
    width:100%; 
    position:absolute;
    z-index:9; 

    .container{
        margin-top:20px;
    }
 
}

.innerbannerImage{
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}